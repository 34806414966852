// Use const or let to declare variables and arrow functions for the event listener
const button = document.getElementById('sideButton');
const htmlElement = document.documentElement; // This is a direct reference to the <html> element
const element = document.getElementById('myElement');
const bodyElement  = document.body;

const closeButton = document.getElementById('closeSlide');

// Use an arrow function for better readability and modern syntax
button.addEventListener('click', () => {
    // Toggle the class 'active' on the HTML element
    htmlElement.classList.toggle('compress-menu');
    bodyElement.classList.toggle('slide-menu-active');

    // Check if the backdrop already exists
    let backdrop = document.getElementById('custom-backdrop');

    if (!backdrop) {
        // If the backdrop doesn't exist, create it
        backdrop = document.createElement('div');
        backdrop.id = 'custom-backdrop';

        backdrop.classList.add('custom-backdrop-styles');

        // Append the backdrop div to the body
        document.body.appendChild(backdrop);
    } else {
        // If the backdrop exists, remove it
        document.body.removeChild(backdrop);
    }
});

closeButton.addEventListener('click', () => {
    htmlElement.classList.remove('compress-menu');
    bodyElement.classList.remove('slide-menu-active');
    let backdrop = document.getElementById('custom-backdrop');
    document.body.removeChild(backdrop);
});


    // Function to create and render a chart
    function createAndRenderChart(selector, options, chartVarName) {
        // Check if chart already exists to avoid unnecessary re-creation
        if (window[chartVarName]) {
            window[chartVarName].updateOptions(options, true); // Update chart options instead of destroying and recreating
        } else {
            window[chartVarName] = new ApexCharts(document.querySelector(selector), options);
            window[chartVarName].render();
        }
    }

    // Define the init function to initialize charts
    function initCharts() {
        // Area chart options
        const optionReport = {
            chart: { 
                type: 'area' ,
                fontFamily: 'Prompt, sans-serif'
            },
            series: [
                { name: 'งานลงทะเบียน MOU', data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 100, 110, 50] },
                { name: 'งานต่อ Work Permit', data: [20, 50, 45, 70, 49, 60, 10, 5, 0, 50, 40, 30] },
                { name: 'งานขึ้นทะเบียน', data: [10, 12, 34, 60, 50, 30, 40, 60, 10, 20, 10, 60] }
            ],
            xaxis: { 
                categories: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'] ,
                style: {
                    fontSize: '18px',
                    fontFamily: 'Prompt, sans-serif',  // Apply to chart title
                    fontWeight: 600
                }
            },
            responsive: [{
                breakpoint: 1000,
                options: {
                     chart: { width: 300 , height: 450},
                    //legend: { position: 'bottom' }
                }
            }]
        };

        // Donut chart options
        const optionsTypeWork = {
            series: [200, 300, 40],
            chart: { 
                type: 'donut' ,
                fontFamily: 'Prompt, sans-serif'
             },
            labels: ['งานลงทะเบียน MOU', 'งานต่อ Work Permit', 'งานขึ้นทะเบียน'],
            options: {
                legend: { position: 'bottom' }
            },
            style: {
                fontSize: '18px',
                fontFamily: 'Prompt, sans-serif',  // Apply to chart title
                fontWeight: 600
            },
            responsive: [{
                breakpoint: 1000,
                options: {
                    //chart: { width: 200 },
                    legend: { position: 'bottom' }
                }
            }]
        };

        // Render both charts
        createAndRenderChart("#reportchart", optionReport, "chartReport");
        createAndRenderChart("#typeWorkChart", optionsTypeWork, "chartTypeWork");
    }

    // Call initCharts when ready
    window.onload = function() {
        initCharts();
    };

    // Optional: Use setTimeout if needed, e.g., to delay chart rendering
    setTimeout(function() {
        initCharts();
    }, 200);  // Delay chart initialization by 1 second (example)